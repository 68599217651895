import ApiService from './api.service'

export default class VisittypeService {

	async getVisittypes() {
        const response = await ApiService.get('api/visittps')
        return response.data.data
    }

	async newVisittype(visit) {
        const response = await ApiService.post('api/visittps', visit)
        return response.data.id
    }

	async updateVisittype(visit) {
        const response = await ApiService.put('api/visittps/'+visit.id, visit)
        return response.data.id
    }

	async deleteVisittype(id) {
        const response = await ApiService.delete('api/visittps/'+id)
        return response
    }

}
