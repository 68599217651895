<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card" id="maincard">
				<Toast/>
				<!--Toolbar class="p-mb-0">
					<template slot="left"-->
						<!--Button label="Új" icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" /-->
						<!--Button label="Töröl" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedVisits || !selectedVisits.length" /-->
						<!--Button label="Teszt" icon="pi pi-plus" class="p-button-info p-mr-2" @click="teszt" style="margin-left: 7px" /-->
                        <!--Dropdown v-model="visitClass" :options="codesVisitClass" optionLabel="value" optionValue="code" placeholder="Select a Class" /-->
					<!--/template-->

					<!--template slot="right">
						<FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="p-mr-2 p-d-inline-block" />
						<Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
					</template-->
				<!--/Toolbar-->


                <!-- Táblázat -->

				<DataTable ref="dt" :value="visits" v-model:expandedRows="expandedRows" dataKey="id" :paginator="true" :rows="25" :filters="filters"
                            sortField="visitname" :sortOrder="1" :loading="loading" @filter="onFilter($event)" @row-click="expandRow"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25,100]"
							currentPageReportTemplate="{first} - {last} / {totalRecords}" class="p-datatable-customers p-datatable-sm p-datatable-hoverable-rows ">
					<template #header>
						<div class="table-header">
                            <div>
                                <Button label="Új" icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" />
                                <h5 class="p-m-0 p-d-inline-block">Karbantartás: Vizit típusok</h5>
                            </div>
							<span class="p-input-icon-left">
                                <i :class="(loadingSearch) ? ['pi', 'pi-spin', 'pi-spinner'] : ['pi', 'pi-search']"/>
                                <InputText v-model="searchGlobal" placeholder="Keresés..." />
                            </span>
						</div>
					</template>

					<Column :expander="true" headerStyle="width: 3rem" />
					<Column field="visitname" header="Megnevezés" sortable headerStyle="width: 20rem" filterMatchMode="contains">
                        <template #filter>
                            <InputText type="text" v-model="filters['visitname']" class="p-column-filter" placeholder="Keresés névre..."/>
                        </template>
                    </Column>
                    <Column field="category" header="Kategória" sortable >
                    </Column>
                    <Column field="visitcost" header="Vizit költsége" sortable >
                    </Column>
					<Column field="intorepteljesites_name" header="Teljesítés riport" sortable filterMatchMode="custom" :filterFunction="filterIgenNem" :excludeGlobalFilter="true">
                        <template #filter>
                            <Checkbox :binary="true" v-model="filters['intorepteljesites_name']" class="p-column-filter"/>
                        </template>
                    </Column>
					<Column field="intorepkoltseg_name" header="Költség riport" sortable filterMatchMode="custom" :filterFunction="filterIgenNem" :excludeGlobalFilter="true">
                        <template #filter>
                            <Checkbox :binary="true" v-model="filters['intorepkoltseg_name']" class="p-column-filter"/>
                        </template>
                    </Column>
					<Column field="intorep150_name" header="150 napos riport" sortable filterMatchMode="custom" :filterFunction="filterIgenNem" :excludeGlobalFilter="true">
                        <template #filter>
                            <Checkbox :binary="true" v-model="filters['intorep150_name']" class="p-column-filter"/>
                        </template>
                    </Column>
					<Column field="admin_only_name" header="Csak admin" sortable filterMatchMode="custom" :filterFunction="filterIgenNem" :excludeGlobalFilter="true">
                        <template #filter>
                            <Checkbox :binary="true" v-model="filters['admin_only_name']" class="p-column-filter"/>
                        </template>
                    </Column>
					<Column field="nstatus_name" header="Numil státusz" sortable filterMatchMode="equals" :excludeGlobalFilter="true">
                        <template #body="slotProps">
                            <span :class="'customer-badge status-' + slotProps.data.nstatus">{{slotProps.data.nstatus_name}}</span>
                        </template>
                        <template #filter>
                            <Dropdown v-model="filters['nstatus_name']" :options="codesNumilStatusOptions" placeholder="Keresés státuszra..." class="p-column-filter" :showClear="true">
                                <template #option="slotProps">
                                    <span :class="'customer-badge status-' + slotProps.option">{{slotProps.option}}</span>
                                </template>
                            </Dropdown>
                        </template>
                    </Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editVisit(slotProps.data.id)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteVisit(slotProps.data)" />
						</template>
					</Column>
					<template #expansion="slotProps">
                        <Card>
                        <template #content>
                            <div class="p-grid">
                                <div class="p-col" style="margin-left: 2rem">
                                    <p class="p-d-inline-block" style="margin-right: 1rem">Megjegyzés:</p>
                                    <p class="p-d-inline" style="white-space: pre-wrap" v-html="slotProps.data.note"></p>
                                </div>
                                <!--div class="p-col">Város: {{ slotProps.data.city }}</div-->
                            </div>
                        </template>                            
                        </Card>
                    </template>				
                </DataTable>


                <!-- Szerkesztő ablak -->

				<Dialog id="dialog" v-model:visible="visitDialog" :style="{width: '450px', 'margin-top': '16px' }" 
                    header="Vizit típus" :modal="true" class="p-fluid"
                    @keydown.enter.ctrl="saveVisit()" @keyup.esc="hideDialog()">
					<span class="p-field p-float-label" style="margin-top: 1rem">
						<InputText id="visitname" v-model.trim="v$.visit.visitname.$model"
                            required="true" :class="{'p-invalid': v$.visit.visitname.$invalid}" autocomplete="new-visit-visitname"
                        />
						<label for="visitname">Megnevezés</label>
						<small class="p-error" v-if="v$.visit.visitname.$error">{{ errorMessages.required }}</small>
					</span>
					<span class="p-field p-float-label" style="margin-top: 1rem">
						<InputText id="category" v-model.trim="v$.visit.category.$model"
                            required="true" :class="{'p-invalid': v$.visit.category.$invalid}" autocomplete="new-visit-category"
                        />
						<label for="category">Kategória</label>
						<small class="p-error" v-if="v$.visit.category.$error">{{ errorMessages.required }}</small>
					</span>
					<span class="p-field p-float-label">
						<InputNumber id="visitcost" v-model="v$.visit.visitcost.$model"
                            required="true" :class="{'p-invalid': v$.visit.visitcost.$invalid}" autocomplete="new-hosp-visitcost"
                        />
						<label for="visitcost">Költség</label>
						<small class="p-error" v-if="v$.visit.visitcost.$error">{{ errorMessages.required }}</small>
					</span>
					<span class="p-field p-float-label">
                        <Dropdown id="nstatus" v-model="v$.visit.nstatus.$model" :options="codesNumilStatus" optionLabel="name" optionValue="code" placeholder=""
                            required="true" :class="{'p-invalid': v$.visit.nstatus.$invalid}"
                        />
						<label for="nstatus">Numil státusz</label>
						<small class="p-error" v-if="v$.visit.nstatus.$error">{{ errorMessages.required }}</small>
					</span>
					<span class="p-field-checkbox">
                        <Checkbox id="custtypeS" v-model="visit.custtypeS" :binary="true"
                        />
						<label for="custtypeS">Szondatáplált</label>
					</span>
					<span class="p-field-checkbox">
                        <Checkbox id="custtypeM" v-model="visit.custtypeM" :binary="true"
                        />
						<label for="custtypeM">Metabolik</label>
					</span>
					<span class="p-field-checkbox">
                        <Checkbox id="intorepteljesites" v-model="visit.intorepteljesites" :binary="true"
                        />
						<label for="intorepteljesites">Teljesítés riportba</label>
					</span>
					<span class="p-field-checkbox">
                        <Checkbox id="intorepkoltseg" v-model="visit.intorepkoltseg" :binary="true"
                        />
						<label for="intorepkoltseg">Költség riportba</label>
					</span>
					<span class="p-field-checkbox">
                        <Checkbox id="intorep150" v-model="visit.intorep150" :binary="true"
                        />
						<label for="intorep150">150 napos riportba</label>
					</span>
					<span class="p-field-checkbox">
                        <Checkbox id="admin_only" v-model="visit.admin_only" :binary="true"
                        />
						<label for="admin_only">Kizárólag admin adhatja hozzá</label>
					</span>
					<span class="p-field p-float-label">
						<Textarea id="description" v-model="visit.note" rows="3" cols="20" style="resize: vertical"
                        />
						<label for="description">Megjegyzés</label>
					</span>

					<template #footer>
                        <small class="p-error p-d-block" v-if="v$.$error">{{ errorMessages.formError }}</small>
						<Button label="Mégse" icon="pi pi-times" class="p-button-text" 
                            v-tooltip.bottom="'ESC'" tabindex="-1"
                            @click="hideDialog"/>
						<Button label="Mentés" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-check'" class="p-button-success" 
                            v-tooltip.bottom="'Ctrl+ENTER'" :disabled="v$.$error || submitted"
                            @click="saveVisit"/>
					</template>
				</Dialog>


                <!-- Törlés kérdés felugró -->

				<Dialog v-model:visible="deleteVisitDialog" :style="{width: '450px'}" header="Megerősítés" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="visit">Biztosan töröljük a vizit típust: <b>{{visit.visitname}}</b>?</span>
					</div>
					<template #footer>
						<Button label="Mégse" icon="pi pi-times" class="p-button-text" @click="deleteVisitDialog = false"/>
						<Button label="Törlés" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-trash'" class="p-button-danger" 
                            @click="deleteVisit"/>
					</template>
				</Dialog>

			</div>
		</div>
	</div>

</template>

<script>
//import { mapGetters, mapActions } from 'vuex'
import VisittypeService from '@/service/backend/visittype.service'
import useVuelidate from '@vuelidate/core'
import { required, errorMessages } from  '@/texts/errorMessages'
import { reactive } from 'vue'

export default {
    setup: () => ({ v$: useVuelidate() }),
    validationConfig: {
        $lazy: true,
        $autoDirty: true
    },
	data() {
        const codesIgenNem = [
            {code: true, name: 'Igen'},
            {code: false, name: ''},
            {code: '' , name: ''}
        ]
        const codesNumilStatus = [
            {code: 'A', name: 'Aktív'},
            {code: 'I', name: 'Inaktív'},
            {code: 'L', name: 'Lezárt'},
            {code: 'E', name: 'Eszközös'}
        ]

        const visit = reactive({})

		return {
			visits: null,
			visitDialog: false,
			deleteVisitDialog: false,
			visit,
			expandedRows: [],
            filters: {
                'global': {value: null},
            },
            submitted: false,
            searchGlobal: "",
            loading: true,
            loadingSearch: false,
            searchInterval: null,
            errorMessages,

            codesIgenNem,
            codesNumilStatus

		}
	},
    validations: {
        visit: {
            visitname: { required },
            category: {  },
            visitcost: { required }, 
            nstatus: { required }
        }
    },
    visitTypeService: null,
	created() {
		this.visitTypeService = new VisittypeService()
    },
    beforeMount() {
        //this.loadCodeStore('visitClass') // Abban az esetben, ha API-s a codeStore - de nem kell ezt tudnia, jöhetne
    },
	mounted() {
        this.loadData();
	},
	methods: {
/*        ...mapActions({
            getCodeStore: 'codeStore/loadCodeStore'
        }), */
        loadData() {
            this.loading = true;

            this.visitTypeService.getVisittypes()
            .then( result => {
                this.visits = result
                this.loading = false
            })
        },
        onFilter() {
            this.loadingSearch = false
        },
        expandRow(e) {
            if(this.expandedRows.length === 0 || this.expandedRows[0].id !== e.data.id ){
                this.expandedRows = this.visits.filter(p => p.id == e.data.id);
            }else{
                this.expandedRows = []
            }
        },
        teszt() {
            this.$toast.add({severity:'success', summary: 'Teszt!', detail: 'Tesztelve!', life: 30000 })
        },
        openDialog(){
            this.visitDialog = true
            this.submitted = false
            this.v$.$reset()
        },
		openNew() {
            this.visit = {}
            this.visit.nstatus = 'A'
            this.visit.custtypeS = true
            this.visit.custtypeM = false
            this.visit.intorepteljesites = true
            this.visit.intorepkoltseg = false
            this.visit.intorep150 = false
            this.visit.admin_only = false
            this.openDialog()
		},
		hideDialog() {
			this.visitDialog = false
            this.submitted = false
		},
		async saveVisit() {

            this.submitted = true;
            this.v$.visit.$touch()

            if (!this.v$.$invalid) {
                    try{
                        let c = this.visit
                        this.visit.nstatus_name = this.codesNumilStatus.find(x => x.code === c.nstatus).name;
                        this.visit.custtypeS_name = this.codesIgenNem.find(x => x.code === c.custtypeS).name;
                        this.visit.custtypeM_name = this.codesIgenNem.find(x => x.code === c.custtypeM).name;
                        this.visit.intorepteljesites_name = this.codesIgenNem.find(x => x.code === c.intorepteljesites).name;
                        this.visit.intorepkoltseg_name = this.codesIgenNem.find(x => x.code === c.intorepkoltseg).name;
                        this.visit.intorep150_name = this.codesIgenNem.find(x => x.code === c.intorep150).name;
                        this.visit.admin_only_name = this.codesIgenNem.find(x => x.code === c.admin_only).name;

                        if (this.visit.id) {
                            await this.visitTypeService.updateVisittype(this.visit)
                            this.visits[this.findIndexById(this.visit.id)] = this.visit
                            this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Frissítve!', life: 3000})
                        }
                        else {
                            this.visit.id = await this.visitTypeService.newVisittype(this.visit)
                            if(this.visits.length == this.$refs.dt.rows){
                                this.visits.pop()
                            }
                            this.visits.unshift(this.visit)
                            this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Létrehozva!', life: 3000})
                        }
                        
                    }catch(e){
                        console.log(e)
                        // TODO server oldali hiba kiírása?
                        this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Valami hiba történt', life: 3000})
                        this.submitted = false
                        return
                    }

                    this.hideDialog()
            
            }

            this.submitted = false

		},
		editVisit(id) {
            this.visit = JSON.parse(JSON.stringify(this.visits.find( d => d.id === id)));
            this.visit.oep = (this.visit.oep == 'Y') ? true : false
            this.openDialog()
		},
		confirmDeleteVisit(visit) {
			this.visit = visit
			this.deleteVisitDialog = true
		},
		async deleteVisit() {
            this.submitted = true
            try{
                await this.visitTypeService.deleteVisittype(this.visit.id)
                this.visits = this.visits.filter(val => val.id !== this.visit.id)
                this.deleteVisitDialog = false
                this.visit = {}
                this.$toast.add({severity:'success', summary: 'Siker', detail: 'Törölve!', life: 3000})
            }catch{
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Valami hiba történt', life: 3000})
            }finally{
                this.submitted = false
            }
		},
		findIndexById(id) {
			let index = -1
			for (let i = 0; i < this.visits.length; i++) {
				if (this.visits[i].id === id) {
					index = i
					break
				}
			}

			return index;
		},
		exportCSV() {
			this.$refs.dt.exportCSV()
		},
        ctrlEntered() {
            //saveVisit
            console.log('ctrlEntered()')
        },
        filterIgenNem(value, filter) {
            if(filter)
                return (value == 'Igen') == filter
            else
                return true
        }
    }, 
    computed: {
        codesNumilStatusOptions() {
            let a = []
            this.codesNumilStatus.forEach(e => {
                a.push(e.name)
            })
            return a
        }
    },
    watch: {
        searchGlobal(val) {
            clearTimeout(this.searchInterval)
            this.searchInterval = setTimeout(() => {
                this.loadingSearch = true
                setTimeout(() => { this.filters['global'].value = val; this.onFilter(); }, 20)
            }, 600)
        }
    }
}
</script>
<style>
.p-datatable-tbody {
    cursor: pointer;
}
.p-datatable-row-expansion {
    cursor: auto;
}
.p-card .p-card-content {
    padding: 0 0 0.2rem 0;
}
</style>

<style scoped lang="postcss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.visit-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .visit-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.visit-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

#maincard {
    padding-top: 0px;
}

h5 {
    padding-left: 1.6rem;
    padding-bottom: 0.6rem;
}

@media screen and (max-width: 960px) {
	::deep() .p-datatable {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
					}
				}
			}
		}
	}

	::deep() .p-visitbar {
		flex-wrap: wrap;

		.p-button {
			margin-bottom: .25rem;
		}
    }
    
}
</style>
